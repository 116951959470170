import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { Routes, RouteStatus, RouteWithSubRoutes } from '../service/route/Routes';
import NotFound from './layout/NotFound';
import Meta from './layout/Meta';
import Header from './layout/Header';
import Navbar from './layout/Navbar';
import Main from './layout/Main';
import Footer from './layout/Footer';
import ScrollToTop from './layout/ScrollToTop';

export default function App() {
  return (
    <Router>
      <Meta />
      <Header />
      <Navbar />
      <Main>
        <Suspense fallback={null}>
          <Switch>
            {Routes.map((route, i) => (
              <RouteWithSubRoutes key={String(i)} {...route} />
            ))}
            <RouteStatus statusCode={404}>
              <NotFound />
            </RouteStatus>
          </Switch>
        </Suspense>
      </Main>
      <Footer />
      <ScrollToTop />
    </Router>
  );
}
