import React from "react";
import { Helmet } from "react-helmet";

export default function Meta() {
  return (
    <Helmet
      defaultTitle="Locatiq Deal Finder"
      titleTemplate="%s | Locatiq Deal Finder"
    >
      <meta charSet="utf-8" />
      <base
        href="/"
        target="_blank"
      />
      <meta
        name="copyright"
        content="Mall IQ, Inc."
      />
      <meta
        name="keywords"
        content="discount, deals, deal finder"
      />
      <meta
        name="robots"
        content="index,follow"
      />
      <meta
        property="og:locale"
        content="en"
      />
      <meta
        property="og:site_name"
        content="Locatiq Deal Finder"
      />
    </Helmet>
  );
}
