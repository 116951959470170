import React, { lazy } from "react";
import { Route } from "react-router-dom";

export const Routes = [
  {
    path: "/",
    exact: true,
    component: lazy(() => import("../../page/Home"))
  },
  {
    path: "/privacy-policy",
    exact: true,
    component: lazy(() => import("../../page/PrivacyPolicy"))
  },
  {
    path: "/contact",
    exact: true,
    component: lazy(() => import("../../page/Contact"))
  },
  {
    path: "/about",
    exact: true,
    component: lazy(() => import("../../page/About"))
  },
  {
    path: "/help",
    exact: true,
    component: lazy(() => import("../../page/Help"))
  }
];

export function RouteWithSubRoutes(route: any) {
  const element = (
    <Route
      path={route.path}
      render={(props) => <route.component {...props} routes={route.routes} />}
    />
  );

  return <>{element}</>;
}

export function RouteStatus(props: any) {
  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) {
          staticContext.statusCode = props.statusCode;
        }

        return <div>{props.children}</div>;
      }}
    />
  );
}
