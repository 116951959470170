import React from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";

export default function Footer() {
  return (
    <footer>
      2021 © Mall IQ, Inc.
      <span style={{ flex: 1 }} />
      <Link to="/privacy-policy">Privacy Policy</Link>
    </footer>
  );
}
