import React from "react";
import "./Main.scss";

type MainProps = {
  children: any;
};

export default function Main({ children }: MainProps) {
  return <main>{children}</main>;
}
