import React from "react";

export default function NotFound() {
  return (
    <>
      <div
        className="text-center"
        style={{ fontSize: 40, marginBottom: 50 }}
      >
        <div className="text-black-50">404</div>
        <div>Not Found.</div>
      </div>
    </>
  );
}
