import React from "react";
import { Link } from "react-router-dom";
import "./Header.scss";

export default function Header() {
  return (
    <header>
      <Link to="/">
        <img
          src="/img/logo.png"
          alt="Locatiq Deal Finder"
        />
      </Link>
    </header>
  );
}
